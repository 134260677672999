export const GQL_FETCH_FORCEFRAME_BANNER_ENTRY = `
   forceFrameHeroBanner: pageComponentsCollection(where: { sys: { id: "2xzYgvGVj6MwFh1hN5eL5m" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_FLEXIBILITY_ENTRY = `
    textVideoFlexibility: pageComponentsCollection(
      where: {sys: {id: "5KSWGv5phDPCtPFMMfJKiA"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MODULARITY_ENTRY = `
    textImageModularity: pageComponentsCollection(
      where: {sys: {id: "MdPsvj6Dzc1dyRKhG1Y3f"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 3) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FLEXIBILITY_PATIENTS_ENTRY = `
    textVideoFlexibilityPatient: pageComponentsCollection(
      where: {sys: {id: "32HhVBPDtOYoyl94pb74MM"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
          }
        }
      }
    }
`;

export const GQL_FETCH_TEST_AND_TRAIN_ENTRY = `
    textImageTestAndTrain: pageComponentsCollection(
      where: {sys: {id: "48AIUrzgB7zKcGa7mxwzFb"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 3) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FF_FEATURE_IMAGE_ENTRY = `
  featureImage: pageComponentsCollection(where: { sys: { id: "4kaDv6BS1Q8Qkf0J1wNwje" } }, limit: 1) {
      items {
        ... on DuplexComponent {
        scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_FF_MAX_CARDS_ENTRY = `
ffMaxCard: pageComponentsCollection( where: {sys: {id: "69Rlzg3aqtz45YmAEZZjuk"}}, limit: 1) {
  items {
    ... on SectionHeader {
      title
      scrollTo
      bodyCopy {
        json
      }
      logo {
        url
        width
        height
        description
      }
      buttonCollection(limit: 2) {
        items {
          sys {
            id
          }
          text
          url
          target
          type
        }
      }
    }
  }
}    
`;

export const GQL_FETCH_FF_FOLD_CARDS_ENTRY = `
ffFoldCard: pageComponentsCollection( where: {sys: {id: "HtAfSTfXawZWviddLkZuR"}}, limit: 1) {
  items {
    ... on SectionHeader {
      scrollTo
      title
      bodyCopy {
        json
      }
      logo {
        url
        width
        height
        description
      }
      buttonCollection(limit: 2) {
        items {
          sys {
            id
          }
          text
          url
          target
          type
        }
      }
    }
  }
}    
`;

export const GQL_FETCH_FAQ_FF_ENTRY = `
faqFF: pageComponentsCollection(where: { sys: { id: "goFhm2FACJvkN75Fs1pw0" } }, limit: 1){
  items{
    ... on Grid{
      scrollTo
      preTitle
      title
      topicCollection{
        items{
          ... on Card{
            title
            bodyCopy{
              json
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_FETCH_FLEXIBLE_PAYMENT_PLAN_ENTRY = `
textImagePaymentPlan: pageComponentsCollection(
  where: { sys: { id: "8Bqqb4gWvMQGdi8In3UVk" } }
      limit: 1
) {
      items {
        ... on DuplexComponent {
          scrollTo
      preTitle
      title
          bodyCopy {
        json
      }
          mediaPrimary {
        description
        url
        width
        height
      }
          mediaSecondary {
        description
        url
        width
        height
      }
          mediaTertiary {
        description
        url
        width
        height
      }
      buttonCollection(limit: 2) {
            items {
              sys {
            id
          }
          text
          url
          target
          type
        }
      }
    }
  }
}
`;

export const GQL_FETCH_FF_VALD_SYSTEMS_ENTRY = `
relatedFFSystems: pageComponentsCollection(
  where: { sys: { id: "4FeY7Ed1v5rqYdbnL76Fml" } }
      limit: 1
) {
      items {
        ... on SectionHeader {
          scrollTo
      preTitle
      title
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_RELATED_PRODUCTS_ENTRY = `
products: productCollection(order: order_ASC, where: { categories: { sys: { id: "40QX5mC0pKMTcdFqeMKAhY" } } }) {
    items {
      sys {
      id
    }
      mediaPrimary {
      description
      url
      width
      height
    }
      logo {
      url
      description
    }
    name
    title
    slug
      bodyCopy {
      json
    }
      button {
      text
      url
      type
      target
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_CTA_ENTRY = `
callToAction: pageComponentsCollection(
  where: { sys: { id: "6IcrHgFdHSwH74VXKOmt9Q" } }
      limit: 1
) {
      items {
        ... on SectionHeader {
          scrollTo
      preTitle
      title
          bodyCopy {
        json
      }
      buttonCollection(limit: 2) {
            items {
              sys {
            id
          }
          text
          url
          target
          type
        }
      }
    }
  }
}
`;
