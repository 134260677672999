export const GQL_ORGANIZATIONS_HERO_COMPONENT_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "50vkxVKCdIxImOj8puGMJm" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        title
        bodyCopy {
          json
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_WHAT_MATTERS_COMPONENT_ENTRY = `
  whatMatters: pageComponentsCollection(where: { sys: { id: "7jpsfBzUSHBCofzVJSMWkl" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_LEAGUE_GRID = `
  league: pageComponentsCollection(where: {sys: {id: "1MG8aOEOOJFrr3Rdq9kHoe"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
      }
    }
  }
  leagueGrid: pageComponentsCollection(where: {sys: {id: "1tLeJlexH4z1cE49Ld31g8"}}, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection {
          items {
            ... on Card {
              mediaPrimary {
                width
                height
                url
                description
              }
              title
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_CLASSROOM_COMPONENT_ENTRY = `
  classroom: pageComponentsCollection(where: { sys: { id: "2Awb3PqzQRknug3k1woGZq" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_PERSONALIZED_COMPONENT_ENTRY = `
  personalized: pageComponentsCollection(where: { sys: { id: "3W74eaESZaOxlcBF3PNc0n" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_RESPOND_COMPONENT_ENTRY = `
  respond: pageComponentsCollection(where: { sys: { id: "5pGYvpN5EaxhfafVoiCG6H" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_PLATFORM_COMPONENT_ENTRY = `
  platform: pageComponentsCollection(where: { sys: { id: "4yeAKGfo21nXyZ1FyZ2xEp" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_ELITE_COMPONENT_ENTRY = `
  elite: pageComponentsCollection(where: { sys: { id: "6TXyKWQG9LCIPgX1pcjHXh" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_PAGE_OUR_SERVICES = `
  description: pageComponentsCollection(where: {sys: {id: "NBIYQV5Acl7z2T7LFnFpV"}}, limit: 1) {
    items {
      ... on SectionHeader {
        bodyCopy {
          json
        }
      }
    }
  }
  ourServices: pageComponentsCollection(where: {sys: {id: "6P0Ra0h1PjAIbEFxFKwuXW"}}, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection {
          items {
            ... on Card {
              mediaPrimary {
                width
                height
                url
                description
              }
              mediaSecondary {
                width
                height
                url
                description
              }
              title
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "39vV5IboJb5CUlKOFe25OC" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_RESEARCH = `
  research: pageComponentsCollection(where: { sys: { id: "6P1xjR7RrXXjP6Tdj2tc0v" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
         mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
