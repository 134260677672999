import { IS_PREVIEW } from '@src/typescriptGlobals/constants';

export const GQL_INTEGRATIONS_PAGE_HERO_COMPONENT_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "3nEN4jKW9mfAuxH0ENV8yh" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_INTEGRATIONS_NO_ADD_COST_BANNER = `
  noCostBanner: pageComponentsCollection(where: { sys: { id: "5IwCqcJXxeALFdB4WQmlTl" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        logo {
          url
          description
          width
          height
        }
      }
    }   
  }
`;

export const GQL_WANT_TO_INTEGRATE_ENTRY = `
wantToIntegrate: pageComponentsCollection(where: {sys: {id: "2fTsMX03sYTYwJWYd2BUgl"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        extraAssetsCollection (limit: 5){
          items {
            ... on SectionHeader {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              buttonCollection {
                items {
                  url
                  text
                  type
                  target
                }
              }
              logo {
                url
                description
                width
                height
              }
              extraAssetsCollection (limit: 1){
                items {
                  ... on Image {
                    media {
                      url
                      description
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_INTEGRATIONS_CARD = `
integrationsCard: pageComponentsCollection(
  where: {sys: {id: "2rSutw1Nn2zmQK4pHk76po"}}
  limit: 1
  preview: ${IS_PREVIEW}
  ) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection (limit: 100) {
          items {
            ... on Card {
              sys {
                id
              }
              preTitle
              title
              subTitle
              bodyCopy {
                json
              }
              mediaPrimary {
                width
                height
                url
                description
                contentType
              }
              buttonCollection (limit: 2) {
                items {
                  url
                  text
                  type
                  target
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_INTEGRATIONS_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "3DS0eHHQU8hXLaLT9pyAF5" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
