export const GQL_HOME_PAGE_HERO_COMPONENT_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "vGdRsy1DtZ7QACP8NlVAy" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_MAIN_TITLE = `
  mainTitle: pageComponentsCollection(where: { sys: { id: "ZMuoMbMjjGyMPsXjKG7yb" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_HOME_SITE_OPTIONS = `
  siteOptions: pageComponentsCollection(where: {sys: {id: "4aVMTvsZqORz9zDhzni8m4"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              slug
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              buttonCollection(limit: 1) {
                items {
                  ... on Button {
                    url 
                    target
                    text
                    type
                  }
                }
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_HOME_MEET_OUR_CLIENT = `
   ourClients: pageComponentsCollection(where: {sys: {id: "51hmLOzokHnsdhyxrOAb87"}}, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
            width
            height
          }
          mediaSecondary {
            url
            description
            width
            height
          }
          extraAssetsCollection {
            items {
              ... on SectionHeader {
                title
                bodyCopy {
                  json
                }
              }
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HOME_LANDING_OBJECTIVE_MEASUREMENT = `
  objectiveMeasurement: pageComponentsCollection(where: {sys: {id: "2rP8PyLI1EFS5pqDo45jr5"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_VALDHUB_IN_ONE_PLACE = `
  inOnePlace: pageComponentsCollection(where: {sys: {id: "4551KXTMdzKXwQS8HRdBPT"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_NORMATIVE_DATA = `
  integratedNormativeData: pageComponentsCollection(where: {sys: {id: "5DH86UJKVCyIKWm1ZEC2xc"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_HOME_PAGE_VALD_SOLUTIONS = `
  valdSolutions: pageComponentsCollection(where: {sys: {id: "2ZpXNH9qgsDfa0ZMDW1oq3"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
  features: pageComponentsCollection(where: {sys: {id: "70HjjcGDbtfhSgnaSZsvkK"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        topicCollection(limit: 6) {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                width
                height
                description
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_HOME_PAGE_OUR_SERVICES = `
  description: pageComponentsCollection(where: {sys: {id: "NBIYQV5Acl7z2T7LFnFpV"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        bodyCopy {
          json
        }
      }
    }
  }
  ourServices: pageComponentsCollection(where: {sys: {id: "6P0Ra0h1PjAIbEFxFKwuXW"}}, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection {
          items {
            ... on Card {
              mediaPrimary {
                width
                height
                url
                description
              }
              mediaSecondary {
                width
                height
                url
                description
              }
              title
              slug
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_HOME_LANDING_WE_EXIST = `
  weExist: pageComponentsCollection(where: { sys: { id: "IXdNZNaKpXqX78GofVbKq" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_HOME_LANDING_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "1fiyxHTGllcb1E0YwfXJPb" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
