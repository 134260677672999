import Dot from '@src/components/Icons/Dot';

import Link from 'next/link';
import Image from 'next/image';

import isEmpty from '@src/utils/isEmpty';
import dateFormatter from '@src/utils/dateFormatter';

import { HitItem } from './SearchResult.type';

const SearchResult = ({ item, components }: HitItem) => {
  return (
    <Link href={item?.pageUrl} className=' flex items-center hover:bg-background-grey-light'>
      <div className='m-[1rem] hidden md:block '>
        <Image src={item?.thumbnail} alt='' className='rounded-[0.4rem]' width={90} height={47} />
      </div>
      <div className='flex flex-1 flex-col gap-[0.6rem] rounded-[0.5rem] p-[1rem] text-body-copy--dark'>
        <div className='font-[500] leading-[2.2rem]'>
          <components.Highlight hit={item} attribute='pageName' />
        </div>

        <div className='flex flex-col gap-[0.5rem] text-[1.2rem] sm:flex-row md:items-center'>
          <div className='flex gap-[0.5rem]'>
            <div className='font-[300]'>{item?.category !== '' ? item?.category : 'Pages'}</div>
            {!isEmpty({ value: item?.createdDate }) ? (
              <>
                <Dot className='mt-[0.5rem] text-body-copy--dark' />
                <time dateTime={item?.createdDate} className='font-[300]'>
                  {dateFormatter(item?.createdDate)}
                </time>
              </>
            ) : (
              ''
            )}
          </div>

          {item?.createdDate !== item?.updatedDate && !isEmpty({ value: item?.updatedDate }) && (
            <div className='font-[300] capitalize sm:ml-auto'>
              Last Updated:{' '}
              <time dateTime={item?.updatedDate} className='font-[300]'>
                {dateFormatter(item?.updatedDate)}
              </time>
            </div>
          )}
        </div>

        <div className='flex gap-[0.5rem]'>
          {item?.languages?.map((lang: string) => {
            return (
              <div
                className={`rounded-[0.2rem] bg-[#DDDDDD] p-[0.2rem] text-[1.2rem] font-[200] text-[#25282A]`}
                key={`${lang}-translations`}
              >
                {lang}
              </div>
            );
          })}
        </div>

        <div className='font-[300] leading-[2.2rem]'>
          <components.Snippet hit={item} attribute='content' />
        </div>
        <div className='!hidden'>
          <components.Highlight hit={item} attribute='content' />
        </div>
      </div>
    </Link>
  );
};

export default SearchResult;
