export const GQL_VALDHUB_BANNER_TEXT_ENTRY = `
  heroBannerText: pageComponentsCollection(where: {sys: {id: "3gvxQ1KjowQpCwO39TKuir"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        logo {
          url
        }
      }
    }
  }
`;

export const GQL_VALDHUB_BANNER_ASSETS_ENTRY = `
  heroBannerAssets: pageComponentsCollection(where: {sys: {id: "qgGtfF95jMmaSqvOqZUeu"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        mediaPrimary {
          url
          width
          height
          description
        }
        extraAssetsCollection {
          items {
            ... on Image {
              sys {
                id
              }
              title
              media {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_CENTRALIZED_ENTRY = `
  centralized: pageComponentsCollection(where: {sys: {id: "3qUYw7XBsn9ur2WadJmhKL"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_FULLY_FEATURED_ENTRY = `
  fullFeatured: pageComponentsCollection(where: {sys: {id: "38velSreUS1fzgqKajk24o"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_ACCURATE_ENTRY = `
  accurate: pageComponentsCollection(where: {sys: {id: "7ds9jIfDrgTlAX0HpGj2V8"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_VALDHUB_CUSTOMIZABLE_ENTRY = `
  customizable: pageComponentsCollection(where: {sys: {id: "4KVLUtBc82719Uj54bDGr8"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_REPORTS_ENTRY = `
  reports: pageComponentsCollection(where: {sys: {id: "1shG78vRhprlzJTsIFKg8A"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_VALDHUB_NORMATIVE_DATA_ENTRY = `
  normativeData: pageComponentsCollection(where: {sys: {id: "74nouMsgRWbTwXgys9KAAL"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_CONTROLS_ENTRY = `
  controls: pageComponentsCollection(where: {sys: {id: "39xyFrfUTkp4wbNUbSinjd"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_VALDHUB_INTEGRATIONS_ENTRY = `
  integrations: pageComponentsCollection(where: {sys: {id: "5RtohTeY0n3f0p3MrQwXpY"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_SYSTEMS_ENTRY = `
  systems: pageComponentsCollection(where: {sys: {id: "4t1yDrKoVrEicOHXAcPfmO"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_BLOGLIST_ENTRY = `
blogList: pageComponentsCollection(where: {sys: {id: "hOGHEZ4VUNs64Mp1sU33R"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        extraAssetsCollection {
          items {
            ... on Image {
              sys {
                id
              }
              url
              media {
                url
                width
                height
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_VALDHUB_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "1nkPGI5LdAsuEeLZDb4aif" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
// ADD MORE COMPONENT ENTRIES BELOW
