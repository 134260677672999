export const GQL_SPORTS_PAGE_HERO_COMPONENT_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "21p0e1brGPugNSpJx4k09f" } }, limit: 1) {
    items {
      ... on Banner {
        title
        scrollTo
        bodyCopy {
          json
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_SPORTS_DATA_INFORMED = `
  getHolistic: pageComponentsCollection(where: { sys: { id: "3FQwWT9seh0qXtuRWZrelR" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SPORTS_TRUSTED_BEST = `
  trustedBest: pageComponentsCollection(where: {sys: {id: "7iFTKobEJ7yFLF0p64SPkW"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
        extraAssetsCollection {
          items {
            ... on Image {
              sys {
                id
              }
              title
              media {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_SPORTS_NEXT_BEST = `
  nextBest: pageComponentsCollection(where: { sys: { id: "1g9F7Wn3wkbfZjyd5koqvn" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SPORTS_CASE_STUDIES_CAROUSEL = `
  caseStudies: pageComponentsCollection(where: {sys: {id: "1E6LjonlXmlkv9x1GKFT7R"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              preTitle
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              mediaSecondary {
                url
                description
                width
                height
              }
              buttonCollection(limit: 2) {
                items {
                  ... on Button {
                    url 
                    target
                    text
                    type
                  }
                }
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_SPORTS_EXPLORE_SPORT_SPECIFIC = `
  sportSpecific: pageComponentsCollection(where: { sys: { id: "3DkjeQKu8PYVn3dYdgwCq6" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SPORTS_ELITE_COMPONENT_ENTRY = `
  elite: pageComponentsCollection(where: { sys: { id: "5bFeoVomruhwnz22qdxxpM" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_SPORTS_VALD_TEAM_CARDS = `
  valdTeam: pageComponentsCollection(where: {sys: {id: "31naYcJB8pnRhAwxfNop3d"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        bodyCopy {
          json
        }
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              preTitle
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              mediaSecondary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_SPORTS_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "2xkO5UD9vdFqJZTj549ubV" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// // ADD MORE COMPONENT ENTRIES BELOW
