'use client';

import useEbookSlideAnimate from '@src/hooks/useEbookSlideAnimate';

import { AnnouncementBarPropsTypes } from './AnnouncementBar.types';
import RenderBodyCopy from '../RenderBodyCopy';
import ParagraphSeparator from '../ParagraphSeparator';
import React from 'react';
import { AnnouncementBarResponseTypes } from '@src/graphql/queries.types';
import fetchAPI from '@src/utils/fetchAPI';
import { GQL_FETCH_ANNOUNCEMENT_BAR } from '@src/graphql/queries';

const AnnouncementBar: React.FC<AnnouncementBarPropsTypes> = () => {
  const ebookSlideRef = useEbookSlideAnimate();
  const [data, setData] = React.useState<AnnouncementBarPropsTypes>();

  React.useEffect(() => {
    fetchAPI<AnnouncementBarResponseTypes>({
      query: GQL_FETCH_ANNOUNCEMENT_BAR,
    }).then((res: any) => setData(res.eBooks));
  }, []);

  const topicCollections = data?.topicCollection?.items;

  const multipleEbook = topicCollections && topicCollections?.length > 1;
  return (
    <div
      ref={ebookSlideRef}
      id='announcement-bar'
      className={`text-white invisible absolute top-[7.4rem] z-[100] flex w-full items-center justify-center gap-[0.4rem] overflow-hidden  bg-primary text-center text-[1.6rem] font-[500] text-body-copy--light shadow-lg ${multipleEbook ? 'py-[1rem]' : 'py-[0.35rem]'}`}
    >
      {data?.title && (
        <p className={`self-center text-left ${multipleEbook ? 'ml-[-24rem]' : ''}`}>
          {data?.title}
        </p>
      )}

      {data?.bodyCopy && (
        <ParagraphSeparator
          className={` self-center text-left ${multipleEbook ? 'ml-[-24rem]' : ''}`}
        >
          <RenderBodyCopy bodyCopy={data?.bodyCopy?.json?.content} className='font-[400]' />
        </ParagraphSeparator>
      )}
      <div className={`relative flex text-center`}>
        {topicCollections?.map((item) => {
          return (
            <a
              key={item?.title}
              href={item?.link}
              target='_blank'
              className={`text-left text-body-copy--light underline hover:text-[#ECEAE8] ${multipleEbook ? 'invisible absolute w-[30rem]' : ''}`}
            >
              <RenderBodyCopy
                bodyCopy={item?.bodyCopy?.json?.content}
                className='font-[400] lg:!text-[1.6rem] [&>u>strong]:!font-[700]'
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default AnnouncementBar;
