export const GQL_SMARTSPEED_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "49MyyQpexCp6aNBoPolQsL"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_SPEED_ENTRY = `
  speed: pageComponentsCollection(where: {sys: {id: "79lRueQskvpBPV9eRn6n7l"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_RELIABLE_ENTRY = `
  reliable: pageComponentsCollection(where: {sys: {id: "nosmj11ip2LbrGBtLQJIQ"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_PLUS_APP_ENTRY = `
  plusApp: pageComponentsCollection(where: {sys: {id: "1AVrAjqqbwDJfmPeaEA9XU"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_WIDE_RANGE_ENTRY = `
  wideRange: pageComponentsCollection(where: {sys: {id: "1cn5T0m20cwCpt6fmkAaJs"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_UP_IN_LIGHTS_ENTRY = `
  inLights: pageComponentsCollection(where: {sys: {id: "5gpbcroGvAH9wgnlYFihVO"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_ACCESSORIES_ENTRY = `
  accessories: pageComponentsCollection(where: {sys: {id: "4cchyG6a7PIZQLPRfuFJGm"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_PLUS_SHOWCASE_ENTRY = `
  plusShowcase: pageComponentsCollection(where: {sys: {id: "3cIMuM4feBHNzBUO5VHmuE"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_GRID_ENTRY = `
  smartSpeedGrid: pageComponentsCollection( where: {sys: {id: "4MUwZ745ya3IvNqs3K4c07"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        extraAssetsCollection {
          items {
            ... on DuplexComponent {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_DASH_ENTRY = `
  dash: pageComponentsCollection(where: {sys: {id: "2VKWSIA18BvAeU2Z1iufeT"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
         mediaSecondary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;
export const GQL_SMARTSPEED_ADDONS_ENTRY = `
  accessoriesAddOns: pageComponentsCollection(where: { sys: { id: "1Qt7p0zEAVJjTd5XwozEfo" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_SMARTSPEED_SMART_JUMP_ENTRY = `
  smartJump: pageComponentsCollection(where: { sys: { id: "4iHOkoqrvu0KHfP24rYVIr" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          description
          url
          width
          height
        }
        mediaSecondary {
          description
          url
          width
          height
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_SMARTSPEED_SMART_JUMP_ACCESSORIES_ENTRY = `
  smartJumpAccessories: pageComponentsCollection(where: {sys: {id: "79JaHE3Cwl9wnLRWcGKI9P"}}, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection(limit: 3) {
          items {
            ... on Card {
              sys {
                id
              }
              mediaPrimary {
                width
                height
                url
                description
              }
              title
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_FLEXIBLE_ENTRY = `
  flexible: pageComponentsCollection(where: {sys: {id: "7Ik2eGs7sh8ByuaWHzB8X9"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SMARTSPEED_RELATED_PRODUCTS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "4FeY7Ed1v5rqYdbnL76Fml"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;

export const GQL_FETCH_SMARTSPEET_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "2FwJzXdweDrI3FKlupBpGm" }}}, limit: 6) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_SMARTSPEED_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "6TxrLQt6T3iGugomlXsTS7" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
