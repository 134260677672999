'use client';
import React, { useEffect, useState } from 'react';
import Dynamic from 'next/dynamic';

import { NavListRenderPropTypes } from './TopNavigation.types';

export const NavItemRender = Dynamic(() => import('./NavItemRender'));

import Container from '../Container';
import Button from '../Button';

import SearchButton from '@src/components/Sections/SearchButton';
import SearchModal from '@src/components/Sections/SearchModal';

import animation from './animate.module.css';

const NavListRender: React.FC<NavListRenderPropTypes> = ({ list }) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = React.useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const menuRef = React.useRef(null);

  const genericHamburgerLine = `absolute top-[0rem] left-[0rem] w-[2.2rem] h-[0.2rem] bg-background-primary transform transition-all duration-500`;

  const megaMenuFirstClasses = `before:bg-background-secondary before:content-[''] before:absolute before:left-[-1px] before:h-[100vh] before:w-[100vw] before:mt-[-3rem] xl:before:!hidden`;
  const megaMenuSecondClasses = ``;

  const handleModalOnClick = () => {
    if (!modalOpen) {
      setModalOpen(true);
      document.documentElement.classList.add('overflow-hidden');
      document.getElementsByClassName('aa-Input');
    } else {
      document
        .getElementsByClassName('search-modal-fade')?.[0]
        ?.classList.add('search-modal-fade-out');
      setTimeout(() => setModalOpen(false), 350);
      document.documentElement.classList.remove('overflow-hidden');
    }
  };

  if (modalOpen) {
    (document.getElementsByClassName('aa-Input')[0] as HTMLElement)?.focus();
  }

  useEffect(() => {
    const handleResize = () => {
      try {
        setIsSmallScreen(window.innerWidth < 1280);
      } catch (error) {
        // Handle the error (e.g., log it) if needed
        // console.error('Error accessing window.innerWidth:', error);
      }
    };

    // Check if window is defined (to avoid ReferenceError during server-side rendering)
    if (typeof window !== 'undefined') {
      setIsSmallScreen(window.innerWidth < 1280);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [modalOpen]); // Empty dependency array to ensure the effect runs only once

  const onToggleMobileNav = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  return (
    <>
      <div
        ref={menuRef}
        className={`fixed bottom-[0] left-[0] col-span-3 mt-[-2.2rem] h-[calc(100%-7.4rem)] w-[100vw] overflow-y-auto overflow-x-hidden bg-background-secondary opacity-0 sm:mt-[-1rem]  xl:hidden ${isHamburgerOpen ? `${animation.fadeIn}` : `${animation.fadeOut}`}`}
      >
        <Container
          HtmlTag='ul'
          className={`!mb-[0] h-[auto] items-center xl:flex xl:h-[100%] xl:[&>li:not(:last-child)]:pr-[1.2rem]`}
        >
          {list.slice(0, 4).map((link) => (
            <NavItemRender
              key={link?.sys?.id}
              link={link}
              onToggleMobileNav={() => setIsHamburgerOpen(false)}
            />
          ))}
        </Container>
        <Container
          HtmlTag='ul'
          className={`h-[auto] items-center gap-[2.4rem] xl:flex xl:h-[100%] [&>li:last-child]:border-none`}
        >
          {list.slice(4, list?.length).map((link) => (
            <NavItemRender
              key={link?.sys?.id}
              link={link}
              onToggleMobileNav={() => setIsHamburgerOpen(false)}
            />
          ))}
        </Container>
      </div>

      {isHamburgerOpen && isSmallScreen ? (
        <>
          <div className='top-0 absolute right-[6rem] ml-auto hidden items-center gap-[3rem] xsm:right-[7rem] md:right-[12rem] md:flex xl:hidden'>
            <Button
              btnClassName='relative bottom-[0.1rem]'
              secondary
              btnText='Get in Touch'
              href='/contact'
              align='justify-start'
            />
            <SearchButton handleOnClick={handleModalOnClick} />
          </div>
        </>
      ) : (
        <>
          <div className='top-0 absolute right-[6rem] col-span-1 ml-auto hidden items-center gap-[3rem] xsm:right-[7rem] md:right-[12rem] md:flex xl:hidden '>
            <Button
              btnClassName='relative bottom-[0.1rem]'
              secondary
              btnText='Get in Touch'
              href='/contact'
              align='justify-start'
            />
            <SearchButton handleOnClick={handleModalOnClick} />
          </div>
          <ul
            className={`adjust-menu col-span-3 h-[auto] items-center xl:flex xl:h-[100%] xl:[&>li:not(:first-child)]:pl-[1.2rem] [&>li:not(:last-child)]:pr-[1.2rem] ${isHamburgerOpen ? `block ${megaMenuFirstClasses}` : 'hidden'} `}
          >
            {list.slice(0, 4).map((link) => (
              <NavItemRender key={link?.sys?.id} link={link} />
            ))}
          </ul>
          <ul
            className={`col-span-3 h-[auto] items-center gap-[2.4rem] xl:flex xl:h-[100%] ${isHamburgerOpen ? `block ${megaMenuSecondClasses}` : 'hidden'} `}
          >
            {list.slice(4, 6).map((link) => (
              <NavItemRender key={link?.sys?.id} link={link} />
            ))}
            <li>
              <Button
                btnClassName='relative bottom-[0.1rem]'
                secondary
                btnText='Get in Touch'
                href='/contact'
                align='justify-start'
              />
            </li>
            <li className='h-[2rem]'>
              <SearchButton handleOnClick={handleModalOnClick} />
            </li>
          </ul>
        </>
      )}
      {modalOpen && <SearchModal handleModalOnClick={handleModalOnClick} />}

      <button
        data-test='hamburger-button'
        id='menu-btn'
        aria-label='close button'
        className={`absolute right-[2rem] col-span-1 ml-[2.4rem]  block h-[2.5rem] w-[1.5rem] cursor-pointer pr-[3rem] transition-all duration-300 focus:outline-none md:right-[6rem] xl:relative xl:hidden ${isHamburgerOpen ? 'mt-[1rem]' : 'mt-[0rem]'}`}
        onClick={onToggleMobileNav}
      >
        <span
          className={`${genericHamburgerLine} ${isHamburgerOpen ? 'w-[2.6rem] translate-y-[0.7rem] rotate-45' : 'translate-y-[0.35rem]'}`}
        />
        <span
          className={`${genericHamburgerLine} ${isHamburgerOpen ? 'opacity-0' : 'translate-y-[1.2rem] opacity-100'}`}
        />
        <span
          className={`${genericHamburgerLine} ${isHamburgerOpen ? 'w-[2.6rem] translate-y-[0.7rem] -rotate-45' : 'translate-y-[2.05rem]'}`}
        />
      </button>
      <div className='absolute right-[6rem] mt-[0.6rem] h-[2.5rem] xsm:right-[7rem] md:hidden'>
        <SearchButton handleOnClick={handleModalOnClick} />
      </div>
    </>
  );
};

export default NavListRender;
