export const GQL_DYNAMO_BANNER_TEXT_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "3DXVU0CyBbya6qhOauB6kz"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_DYNAMO_TESTS_ENTRY = `
  tests: pageComponentsCollection(where: {sys: {id: "4yJ5aivQfoRXtB43FDVP9k"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_MODULARITY_ENTRY = `
  modularity: pageComponentsCollection(where: {sys: {id: "6B5eDUkJaeDyV1WS6ytGwe"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_DYNAMO_ENGAGEMENT_ENTRY = `
  engagement: pageComponentsCollection(where: {sys: {id: "MJMPxlzO3LbuKeoxCiYwI"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_DYNAMO_USEABILITY_ENTRY = `
  useability: pageComponentsCollection(where: {sys: {id: "4QYDGEcbkwRl4HXjgCMdN0"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_DYNAMO_EFFICIENCY_ENTRY = `
  efficiency: pageComponentsCollection(where: {sys: {id: "3Jaqz7LgywMVfyAcu7wQmx"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
        }
        mediaSecondary {
          url
        }
        mediaTertiary {
          url
        }
        mediaQuaternary {
          url
        }
      }
    }
  }
`;

export const GQL_DYNAMO_VISIBILITY_ENTRY = `
  visibility: pageComponentsCollection(where: {sys: {id: "4uDDOND2ufs38DIuBNUv6q"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_VERSATILITY_ENTRY = `
  versatility: pageComponentsCollection(where: {sys: {id: "5VybS6w9hGBzttWm2K9eA9"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;
export const GQL_DYNAMO_DYNAMO_PLUS_ENTRY = `
  dynamoPlus: pageComponentsCollection(where: {sys: {id: "4L0h2GSl5ThZPMPaLuQ7Z"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            type
            url
            target
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_DYNAMO_LIGHT_ENTRY = `
  dynamoLight: pageComponentsCollection(where: {sys: {id: "4MC76a2LTXFCSNtQv1i0LM"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_DYNAMO_MAX_ENTRY = `
  dynamoMax: pageComponentsCollection(where: {sys: {id: "4BrvceWTXd48yD5Ky4mgWj"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
        }
        mediaSecondary {
          url
        }
        mediaTertiary {
          url
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_FLEXIBILITY_ENTRY = `
  flexibility: pageComponentsCollection(where: {sys: {id: "5spyJRV4QXttpoYdQHM78h"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_DYNAMO_RELATED_PRODUCTS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "3SIBbpw9dy60pDWhpDmpgL"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;

export const GQL_DYNAMO_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "5xFa3ER6CLLrIlnluuRIDD" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_DYNAMO_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "hfHJQybgGpHFVZVBxeJCh" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
// ADD MORE COMPONENT ENTRIES BELOW
