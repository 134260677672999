import { IS_PREVIEW } from '@src/typescriptGlobals/constants';
import { GQL_PAGE_ID_TYPES } from '@src/typescriptGlobals/contentful.types';

import {
  GQL_ORGANIZATIONS_CLASSROOM_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_ELITE_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_HERO_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_PERSONALIZED_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_PLATFORM_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_RESPOND_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_WHAT_MATTERS_COMPONENT_ENTRY,
  GQL_ORGANIZATIONS_PAGE_OUR_SERVICES,
  GQL_ORGANIZATIONS_CTA,
  GQL_ORGANIZATIONS_RESEARCH,
  GQL_ORGANIZATIONS_LEAGUE_GRID,
} from './pageQueries/organizations/organizationsPageQueries';
import { GQL_KNOWLEDGE_BASE_PAGE_HERO_COMPONENT_ENTRY } from './pageQueries/knowledgeBasePageQueries';
import {
  GQL_APPLICATIONS_PAGE_HERO_COMPONENT_ENTRY,
  GQL_APPLICATIONS_VALDHUB_ENTRY,
  GQL_ORGANIZATIONS_MANAGEMENT_INFORMED,
  GQL_ORGANIZATIONS_DATA_INFORMED,
  GQL_ORGANIZATIONS_MEASUREMENT,
  GQL_ORGANIZATIONS_NORMATIVE,
  GQL_ORGANIZATIONS_INTEGRATIONS,
  GQL_ORGANIZATIONS_TESTIMONIAL,
  GQL_ORGANIZATIONS_TESTIMONIALS,
  GQL_APPLICATIONS_CTA,
  GQL_APPLICATIONS_USAGES_ENTRY,
  GQL_APPLICATIONS_EVERY_PERFORMANCE,
  GQL_APPLICATIONS_ANALYSIS,
} from './pageQueries/applications/applicationsPageQueries';
import {
  GQL_SPORTS_PAGE_HERO_COMPONENT_ENTRY,
  GQL_SPORTS_DATA_INFORMED,
  GQL_SPORTS_TRUSTED_BEST,
  GQL_SPORTS_NEXT_BEST,
  GQL_SPORTS_EXPLORE_SPORT_SPECIFIC,
  GQL_SPORTS_CASE_STUDIES_CAROUSEL,
  GQL_SPORTS_ELITE_COMPONENT_ENTRY,
  GQL_SPORTS_VALD_TEAM_CARDS,
  GQL_SPORTS_CTA,
} from './pageQueries/sports/sportsPageQueries';
import {
  GQL_PRODUCTS_PAGE_HERO_COMPONENT_ENTRY,
  GQL_PRODUCTS_MEASURE_MATTTERS,
  GQL_PRODUCTS_MEASUREMENT_SOLUTIONS,
  GQL_PRODUCTS_CENTRALIZED_PLATFORM_HERO,
  GQL_PRODUCTS_NORMS_MEASURE,
  GQL_PRODUCTS_TEN_MILLIONS_DATAPOINTS,
  GQL_PRODUCTS_EXERCISE_TOOLS,
  GQL_PRODUCTS_AIRBANDS_BFR,
  GQL_PRODUCTS_ATHLETES_SUCCEED_HERO,
  GQL_PRODUCTS_VALD_TEAM_CARDS,
  GQL_PRODUCTS_SPORTS_CTA,
} from './pageQueries/product/productsPageQueries';
import {
  GQL_HOME_PAGE_HERO_COMPONENT_ENTRY,
  GQL_HOME_PAGE_OUR_SERVICES,
  GQL_FETCH_HOME_LANDING_WE_EXIST,
  GQL_FETCH_HOME_LANDING_CTA,
  GQL_HOME_PAGE_VALD_SOLUTIONS,
  GQL_FETCH_HOME_LANDING_OBJECTIVE_MEASUREMENT,
  GQL_FETCH_HOME_NORMATIVE_DATA,
  GQL_FETCH_HOME_MAIN_TITLE,
  GQL_FETCH_HOME_MEET_OUR_CLIENT,
  GQL_FETCH_HOME_SITE_OPTIONS,
  GQL_FETCH_HOME_VALDHUB_IN_ONE_PLACE,
} from './pageQueries/home/homePageQueries';
import {
  GQL_FETCH_AIRBANDS_CTA_ENTRY,
  GQL_FETCH_AIRBANDS_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_BANNER_ENTRY,
  GQL_FETCH_BFR_TRAINING_ENTRY,
  GQL_FETCH_CONVENIENCE_ENTRY,
  GQL_FETCH_FULLY_FEATURED_ENTRY,
  GQL_FETCH_VALD_SYSTEMS_ENTRY,
  GQL_FETCH_WHY_BFR_ENTRY,
  GQL_FETCH_WHY_ELIMINATE_UNCERTAINTY_ENTRY,
} from './pageQueries/airbandsPageQueries';
import {
  GQL_FETCH_FAQ_FF_ENTRY,
  GQL_FETCH_FF_FEATURE_IMAGE_ENTRY,
  GQL_FETCH_FF_FOLD_CARDS_ENTRY,
  GQL_FETCH_FF_MAX_CARDS_ENTRY,
  GQL_FETCH_FF_VALD_SYSTEMS_ENTRY,
  GQL_FETCH_FLEXIBILITY_ENTRY,
  GQL_FETCH_FLEXIBILITY_PATIENTS_ENTRY,
  GQL_FETCH_FLEXIBLE_PAYMENT_PLAN_ENTRY,
  GQL_FETCH_FORCEFRAME_BANNER_ENTRY,
  GQL_FETCH_FORCEFRAME_CTA_ENTRY,
  GQL_FETCH_FORCEFRAME_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_MODULARITY_ENTRY,
  GQL_FETCH_TEST_AND_TRAIN_ENTRY,
} from './pageQueries/forceFramePageQueries';
import {
  GQL_FETCH_NB_CTA_ENTRY,
  GQL_FETCH_NB_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_NB_BANNER_ENTRY,
  GQL_FETCH_NB_BFR_TRAINING_ENTRY,
  GQL_FETCH_NB_USABILITY_ENTRY,
  GQL_FETCH_NB_TEST_ECCENTRIC_ENTRY,
  GQL_FETCH_NB_CONVENIENCE_ENTRY,
  GQL_FETCH_NB_FLEXIBILITY,
  GQL_FETCH_NB_FEATURE_ENTRY,
  GQL_FETCH_NB_RELATED_SYSTEMS_ENTRY,
} from './pageQueries/nordbordPageQueries';
import {
  GQL_FETCH_MK_CTA_ENTRY,
  GQL_FETCH_MK_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_MK_BANNER_ENTRY,
  GQL_FETCH_MK_USABILITY_ENTRY,
  GQL_FETCH_MK_TEST_ECCENTRIC_ENTRY,
  GQL_FETCH_MK_VALD_SYSTEMS_ENTRY,
  GQL_FETCH_MK_CONVENIENCE_ENTRY,
  GQL_FETCH_MK_EDUCATION_DELIVERED_ENTRY,
} from './pageQueries/mskedPageQueries';
import {
  // GQL_FETCH_FD_CTA_ENTRY,
  GQL_FETCH_FD_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_FD_BANNER_ENTRY,
  GQL_FD_VERSATILITY_ENTRY,
  GQL_FETCH_FD_BFR_TRAINING_ENTRY,
  GQL_FETCH_FD_CONVENIENCE_ENTRY,
  GQL_FETCH_FD_FULLY_FEATURED_ENTRY,
  GQL_FETCH_FD_VALD_SYSTEMS_ENTRY,
  GQL_FETCH_FD_FLEXIBILITY_ENTRY,
  GQL_FETCH_FD_USABILITY_ENTRY,
  GQL_FETCH_INTRO_NORMS_ENTRY,
  GQL_FETCH_FD_BLOG_CAROUSEL_ENTRY,
  GQL_FETCH_FD_BLOG_CAROUSEL_POSTS_ENTRY,
  GQL_FETCH_FD_RELATED_SYSTEMS_ENTRY,
  GQL_FETCH_FD_ACCESSORIES_ENTRY,
  GQL_FETCH_FD_VALIDATED_ENTRY,
  GQL_FETCH_FD_CTA_ENTRY,
} from './pageQueries/forceDecks/forceDecksPageQueries';
import {
  GQL_FETCH_HT_CTA_ENTRY,
  GQL_FETCH_HT_RELATED_PRODUCTS_ENTRY,
  GQL_FETCH_HT_HOTSPOTS,
  GQL_FETCH_HT_BANNER_ENTRY,
  GQL_FETCH_HT_BFR_TRAINING_ENTRY,
  GQL_FETCH_HT_CONVENIENCE_ENTRY,
  GQL_FETCH_HT_FEATURE_ENTRY,
  GQL_FETCH_HT_FLEXIBILITY,
  GQL_FETCH_HT_WHY_ELIMINATE_UNCERTAINTY_ENTRY,
  GQL_FETCH_HT_RELATED_SYSTEMS_ENTRY,
} from './pageQueries/humanTrakPageQueries';
import {
  GQL_SCOREIT_CONVENIENT_ENTRY,
  GQL_SCOREIT_CTA_ENTRY,
  GQL_SCOREIT_DELIVERED_ENTRY,
  GQL_SCOREIT_DIGITAL_PROMS_ENTRY,
  GQL_SCOREIT_HERO_ENTRY,
  GQL_SCOREIT_PRODUCTS_ENTRY,
  GQL_SCOREIT_PROMS_LIBRARY_ENTRY,
  GQL_SCOREIT_RELATED_PRODUCTS_ENTRY,
  GQL_SCOREIT_VALIDATED_ENTRY,
} from './pageQueries/scoreIt/scoreItPageQueries';
import {
  GQL_TELEHAB_PRESCRIPTION_ENTRY,
  GQL_TELEHAB_CTA_ENTRY,
  GQL_TELEHAB_HERO_ENTRY,
  GQL_TELEHAB_PRODUCTS_ENTRY,
  GQL_TELEHAB_RELATED_PRODUCTS_ENTRY,
  GQL_TELEHAB_EXERCISE_LIBRARY_ENTRY,
  GQL_TELEHAB_PROGRAM_BUILD_ENTRY,
  GQL_TELEHAB_MONITORING_ENTRY,
  GQL_TELEHAB_ENGAGEMENT_ENTRY,
} from './pageQueries/teleHab/teleHabPageQueries';
import {
  GQL_SMARTSPEED_SPEED_ENTRY,
  GQL_SMARTSPEED_HERO_ENTRY,
  GQL_SMARTSPEED_RELIABLE_ENTRY,
  GQL_SMARTSPEED_PLUS_APP_ENTRY,
  GQL_SMARTSPEED_WIDE_RANGE_ENTRY,
  GQL_SMARTSPEED_UP_IN_LIGHTS_ENTRY,
  GQL_SMARTSPEED_ACCESSORIES_ENTRY,
  GQL_SMARTSPEED_PLUS_SHOWCASE_ENTRY,
  GQL_SMARTSPEED_GRID_ENTRY,
  GQL_SMARTSPEED_DASH_ENTRY,
  GQL_SMARTSPEED_ADDONS_ENTRY,
  GQL_SMARTSPEED_SMART_JUMP_ENTRY,
  GQL_SMARTSPEED_SMART_JUMP_ACCESSORIES_ENTRY,
  GQL_SMARTSPEED_FLEXIBLE_ENTRY,
  GQL_SMARTSPEED_CTA_ENTRY,
  GQL_FETCH_SMARTSPEET_RELATED_PRODUCTS_ENTRY,
  GQL_SMARTSPEED_RELATED_PRODUCTS_ENTRY,
} from './pageQueries/smartSpeed/smartSpeedPageQueries';
import {
  GQL_BLOGLIST_ENTRY,
  GQL_VALDHUB_ACCURATE_ENTRY,
  GQL_VALDHUB_BANNER_ASSETS_ENTRY,
  GQL_VALDHUB_BANNER_TEXT_ENTRY,
  GQL_VALDHUB_CENTRALIZED_ENTRY,
  GQL_VALDHUB_CONTROLS_ENTRY,
  GQL_VALDHUB_CTA_ENTRY,
  GQL_VALDHUB_CUSTOMIZABLE_ENTRY,
  GQL_VALDHUB_FULLY_FEATURED_ENTRY,
  GQL_VALDHUB_INTEGRATIONS_ENTRY,
  GQL_VALDHUB_NORMATIVE_DATA_ENTRY,
  GQL_VALDHUB_REPORTS_ENTRY,
  GQL_VALDHUB_SYSTEMS_ENTRY,
} from './pageQueries/valdHub/valdHubPageQueries';
import {
  GQL_DYNAMO_BANNER_TEXT_ENTRY,
  GQL_DYNAMO_CTA_ENTRY,
  GQL_DYNAMO_DYNAMO_LIGHT_ENTRY,
  GQL_DYNAMO_DYNAMO_PLUS_ENTRY,
  GQL_DYNAMO_DYNAMO_MAX_ENTRY,
  GQL_DYNAMO_EFFICIENCY_ENTRY,
  GQL_DYNAMO_ENGAGEMENT_ENTRY,
  GQL_DYNAMO_FLEXIBILITY_ENTRY,
  GQL_DYNAMO_MODULARITY_ENTRY,
  GQL_DYNAMO_PRODUCTS_ENTRY,
  GQL_DYNAMO_RELATED_PRODUCTS_ENTRY,
  GQL_DYNAMO_TESTS_ENTRY,
  GQL_DYNAMO_USEABILITY_ENTRY,
  GQL_DYNAMO_VERSATILITY_ENTRY,
  GQL_DYNAMO_VISIBILITY_ENTRY,
} from './pageQueries/dynamo/dynamoPageQueries';
import {
  GQL_FETCH_INTEGRATIONS_CARD,
  GQL_FETCH_INTEGRATIONS_CTA,
  GQL_INTEGRATIONS_NO_ADD_COST_BANNER,
  GQL_INTEGRATIONS_PAGE_HERO_COMPONENT_ENTRY,
  GQL_WANT_TO_INTEGRATE_ENTRY,
} from './pageQueries/integrations/integrationsPageQueries';

// =============================================
//              SITE MAPS QUERY
// =============================================
export const GQL_FETCH_SITEMAPS_DATA = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
        translationsCollection (preview: ${IS_PREVIEW}, where: {sys: {id_exists: true}}) {
          items {
            languageCode
            slug
          }
        }
      }
    }
    pageCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vp"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

// =============================================
//                 LANDING PAGE QUERIES
// =============================================
export const GQL_FETCH_LANDING_PAGE_METADATA = `
  query($pageId: String!) {
    page(id: $pageId, preview: ${IS_PREVIEW}) {
      seoMetadata {
        title
        description
        previewImage {
          url
        }
        index
        follow
      }
    }
  }
`;

export const GQL_FETCH_HOME_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HOME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_HOME_MAIN_TITLE}
      ${GQL_HOME_PAGE_HERO_COMPONENT_ENTRY}
      ${GQL_FETCH_HOME_SITE_OPTIONS}
      ${GQL_FETCH_HOME_MEET_OUR_CLIENT}
      ${GQL_FETCH_HOME_LANDING_OBJECTIVE_MEASUREMENT}
      ${GQL_FETCH_HOME_VALDHUB_IN_ONE_PLACE}
      ${GQL_FETCH_HOME_NORMATIVE_DATA}
      ${GQL_HOME_PAGE_VALD_SOLUTIONS}
      ${GQL_HOME_PAGE_OUR_SERVICES}
      ${GQL_FETCH_HOME_LANDING_WE_EXIST}
      ${GQL_FETCH_HOME_LANDING_CTA}
    }
    testimonials: testimonialCollection(where: {  categories: { slug: "home" }, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_SCORE_IT_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SCOREIT_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_SCOREIT_HERO_ENTRY}
      ${GQL_SCOREIT_DIGITAL_PROMS_ENTRY}
      ${GQL_SCOREIT_CONVENIENT_ENTRY}
      ${GQL_SCOREIT_VALIDATED_ENTRY}
      ${GQL_SCOREIT_DELIVERED_ENTRY}
      ${GQL_SCOREIT_PROMS_LIBRARY_ENTRY}
      ${GQL_SCOREIT_RELATED_PRODUCTS_ENTRY}
      ${GQL_SCOREIT_CTA_ENTRY}
    }
    ${GQL_SCOREIT_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_TELEHAB_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.TELEHAB_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_TELEHAB_HERO_ENTRY}
      ${GQL_TELEHAB_PRESCRIPTION_ENTRY}
      ${GQL_TELEHAB_EXERCISE_LIBRARY_ENTRY}
      ${GQL_TELEHAB_PROGRAM_BUILD_ENTRY}
      ${GQL_TELEHAB_MONITORING_ENTRY}
      ${GQL_TELEHAB_ENGAGEMENT_ENTRY}
      ${GQL_TELEHAB_RELATED_PRODUCTS_ENTRY}
      ${GQL_TELEHAB_CTA_ENTRY}
    }
    ${GQL_TELEHAB_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_SMARTSPEED_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SMARTSPEED_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_SMARTSPEED_HERO_ENTRY}
      ${GQL_SMARTSPEED_SPEED_ENTRY}
      ${GQL_SMARTSPEED_RELIABLE_ENTRY}
      ${GQL_SMARTSPEED_PLUS_APP_ENTRY}
      ${GQL_SMARTSPEED_WIDE_RANGE_ENTRY}
      ${GQL_SMARTSPEED_UP_IN_LIGHTS_ENTRY}
      ${GQL_SMARTSPEED_ACCESSORIES_ENTRY}
      ${GQL_SMARTSPEED_PLUS_SHOWCASE_ENTRY}
      ${GQL_SMARTSPEED_GRID_ENTRY}
      ${GQL_SMARTSPEED_DASH_ENTRY}
      ${GQL_SMARTSPEED_ADDONS_ENTRY}
      ${GQL_SMARTSPEED_SMART_JUMP_ENTRY}
      ${GQL_SMARTSPEED_SMART_JUMP_ACCESSORIES_ENTRY}
      ${GQL_SMARTSPEED_FLEXIBLE_ENTRY}
      ${GQL_SMARTSPEED_RELATED_PRODUCTS_ENTRY}
      ${GQL_SMARTSPEED_CTA_ENTRY}
    }
    ${GQL_FETCH_SMARTSPEET_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_VALDHUB_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.VALDHUB_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_VALDHUB_BANNER_TEXT_ENTRY}
      ${GQL_VALDHUB_CENTRALIZED_ENTRY}
      ${GQL_VALDHUB_FULLY_FEATURED_ENTRY}
      ${GQL_VALDHUB_ACCURATE_ENTRY}
      ${GQL_VALDHUB_CUSTOMIZABLE_ENTRY}
      ${GQL_VALDHUB_REPORTS_ENTRY}
      ${GQL_VALDHUB_NORMATIVE_DATA_ENTRY}
      ${GQL_VALDHUB_CONTROLS_ENTRY}
      ${GQL_VALDHUB_INTEGRATIONS_ENTRY}
      ${GQL_VALDHUB_SYSTEMS_ENTRY}
      ${GQL_BLOGLIST_ENTRY}
      ${GQL_VALDHUB_BANNER_ASSETS_ENTRY}
      ${GQL_VALDHUB_CTA_ENTRY}
    }
  }
`;

export const GQL_FETCH_DYNAMO_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.DYNAMO_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_DYNAMO_BANNER_TEXT_ENTRY}
      ${GQL_DYNAMO_TESTS_ENTRY}
      ${GQL_DYNAMO_MODULARITY_ENTRY}
      ${GQL_DYNAMO_ENGAGEMENT_ENTRY}
      ${GQL_DYNAMO_USEABILITY_ENTRY}
      ${GQL_DYNAMO_EFFICIENCY_ENTRY}
      ${GQL_DYNAMO_VISIBILITY_ENTRY}
      ${GQL_DYNAMO_VERSATILITY_ENTRY}
      ${GQL_DYNAMO_DYNAMO_PLUS_ENTRY}
      ${GQL_DYNAMO_DYNAMO_LIGHT_ENTRY}
      ${GQL_DYNAMO_DYNAMO_MAX_ENTRY}
      ${GQL_DYNAMO_FLEXIBILITY_ENTRY}
      ${GQL_DYNAMO_RELATED_PRODUCTS_ENTRY}
      ${GQL_DYNAMO_CTA_ENTRY}
    }
    ${GQL_DYNAMO_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_GET_IN_TOUCH_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.GET_IN_TOUCH_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_KNOWLEDGE_BASE_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HOME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      ${GQL_KNOWLEDGE_BASE_PAGE_HERO_COMPONENT_ENTRY}
    }
  }
`;

export const GQL_FETCH_ORGANIZATIONS_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.ORGANIZATIONS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_ORGANIZATIONS_HERO_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_WHAT_MATTERS_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_LEAGUE_GRID}
      ${GQL_ORGANIZATIONS_CLASSROOM_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_PERSONALIZED_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_RESPOND_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_PLATFORM_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_ELITE_COMPONENT_ENTRY}
      ${GQL_ORGANIZATIONS_PAGE_OUR_SERVICES}
      ${GQL_ORGANIZATIONS_CTA}
      ${GQL_ORGANIZATIONS_RESEARCH}
    }
  }
`;

export const GQL_FETCH_APPLICATIONS_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.APPLICATIONS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_APPLICATIONS_PAGE_HERO_COMPONENT_ENTRY}
      ${GQL_APPLICATIONS_VALDHUB_ENTRY}
      ${GQL_APPLICATIONS_USAGES_ENTRY}
      ${GQL_ORGANIZATIONS_DATA_INFORMED}
      ${GQL_ORGANIZATIONS_MANAGEMENT_INFORMED}
      ${GQL_ORGANIZATIONS_MEASUREMENT}
      ${GQL_APPLICATIONS_ANALYSIS}
      ${GQL_ORGANIZATIONS_NORMATIVE}
      ${GQL_ORGANIZATIONS_INTEGRATIONS}
      ${GQL_ORGANIZATIONS_TESTIMONIAL}
      ${GQL_ORGANIZATIONS_TESTIMONIALS}
      ${GQL_APPLICATIONS_CTA}
      ${GQL_APPLICATIONS_EVERY_PERFORMANCE}
    }
    
    testimonials: testimonialCollection(where: {  categories: { slug: "applications" }, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_PRODUCTS_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.PRODUCTS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_PRODUCTS_PAGE_HERO_COMPONENT_ENTRY}
      ${GQL_PRODUCTS_MEASURE_MATTTERS}
      ${GQL_PRODUCTS_MEASUREMENT_SOLUTIONS}
      ${GQL_PRODUCTS_CENTRALIZED_PLATFORM_HERO}
      ${GQL_PRODUCTS_NORMS_MEASURE}
      ${GQL_PRODUCTS_TEN_MILLIONS_DATAPOINTS}
      ${GQL_PRODUCTS_EXERCISE_TOOLS}
      ${GQL_PRODUCTS_AIRBANDS_BFR}
      ${GQL_PRODUCTS_ATHLETES_SUCCEED_HERO}
      ${GQL_PRODUCTS_VALD_TEAM_CARDS}
      ${GQL_PRODUCTS_SPORTS_CTA}
    }
  }
`;

export const GQL_FETCH_SPORTS_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SPORTS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_SPORTS_PAGE_HERO_COMPONENT_ENTRY}
      ${GQL_SPORTS_DATA_INFORMED}
      ${GQL_SPORTS_TRUSTED_BEST}
      ${GQL_SPORTS_NEXT_BEST}
      ${GQL_SPORTS_EXPLORE_SPORT_SPECIFIC}
      ${GQL_SPORTS_CASE_STUDIES_CAROUSEL}
      ${GQL_SPORTS_ELITE_COMPONENT_ENTRY}
      ${GQL_SPORTS_VALD_TEAM_CARDS}
      ${GQL_SPORTS_CTA}
      
    }
  }
`;

export const GQL_FETCH_INTEGRATIONS_LANDING_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.INTEGRATIONS_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_INTEGRATIONS_PAGE_HERO_COMPONENT_ENTRY}
      ${GQL_INTEGRATIONS_NO_ADD_COST_BANNER}
      ${GQL_WANT_TO_INTEGRATE_ENTRY}
      ${GQL_FETCH_INTEGRATIONS_CARD}
      ${GQL_FETCH_INTEGRATIONS_CTA}
    }
  }
`;

// =============================================
//                 PRODUCT PAGES QUERIES
// =============================================

export const GQL_FETCH_AIR_BANDS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.AIRBANDS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_BANNER_ENTRY}
      ${GQL_FETCH_BFR_TRAINING_ENTRY}
      ${GQL_FETCH_WHY_BFR_ENTRY}
      ${GQL_FETCH_WHY_ELIMINATE_UNCERTAINTY_ENTRY}
      ${GQL_FETCH_CONVENIENCE_ENTRY}
      ${GQL_FETCH_FULLY_FEATURED_ENTRY}
      ${GQL_FETCH_VALD_SYSTEMS_ENTRY}
      ${GQL_FETCH_AIRBANDS_CTA_ENTRY}
    }
    ${GQL_FETCH_AIRBANDS_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.FORCEFRAME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_FORCEFRAME_BANNER_ENTRY}
      ${GQL_FETCH_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_MODULARITY_ENTRY}
      ${GQL_FETCH_FLEXIBILITY_PATIENTS_ENTRY}
      ${GQL_FETCH_TEST_AND_TRAIN_ENTRY}
      ${GQL_FETCH_FF_FEATURE_IMAGE_ENTRY}
      ${GQL_FETCH_FAQ_FF_ENTRY}
      ${GQL_FETCH_FF_MAX_CARDS_ENTRY}
      ${GQL_FETCH_FF_FOLD_CARDS_ENTRY}
      ${GQL_FETCH_FLEXIBLE_PAYMENT_PLAN_ENTRY}
      ${GQL_FETCH_FF_VALD_SYSTEMS_ENTRY}
      ${GQL_FETCH_FORCEFRAME_CTA_ENTRY}
    }
    ${GQL_FETCH_FORCEFRAME_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.NORDBORD_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
     sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_NB_BANNER_ENTRY}
      ${GQL_FETCH_NB_BFR_TRAINING_ENTRY}
      ${GQL_FETCH_NB_USABILITY_ENTRY}
      ${GQL_FETCH_NB_TEST_ECCENTRIC_ENTRY}
      ${GQL_FETCH_NB_FLEXIBILITY}
      ${GQL_FETCH_NB_CONVENIENCE_ENTRY}
      ${GQL_FETCH_NB_FEATURE_ENTRY}
      ${GQL_FETCH_NB_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_NB_CTA_ENTRY}
    }
    ${GQL_FETCH_NB_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_MSKED_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.MSKED_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
     sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_MK_BANNER_ENTRY}
      ${GQL_FETCH_MK_EDUCATION_DELIVERED_ENTRY}
      ${GQL_FETCH_MK_USABILITY_ENTRY}
      ${GQL_FETCH_MK_TEST_ECCENTRIC_ENTRY}
      ${GQL_FETCH_MK_CONVENIENCE_ENTRY}
      ${GQL_FETCH_MK_VALD_SYSTEMS_ENTRY}
      ${GQL_FETCH_MK_CTA_ENTRY}
    }
    ${GQL_FETCH_MK_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.FORCEDECKS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
     sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_FD_BANNER_ENTRY}
      ${GQL_FETCH_FD_BFR_TRAINING_ENTRY}
      ${GQL_FETCH_FD_USABILITY_ENTRY}
      ${GQL_FD_VERSATILITY_ENTRY}
      ${GQL_FETCH_FD_CONVENIENCE_ENTRY}
      ${GQL_FETCH_INTRO_NORMS_ENTRY}
      ${GQL_FETCH_FD_FULLY_FEATURED_ENTRY}
      ${GQL_FETCH_FD_VALD_SYSTEMS_ENTRY}
      ${GQL_FETCH_FD_BLOG_CAROUSEL_ENTRY}
      ${GQL_FETCH_FD_ACCESSORIES_ENTRY}
      ${GQL_FETCH_FD_VALIDATED_ENTRY}
      ${GQL_FETCH_FD_BLOG_CAROUSEL_POSTS_ENTRY}
      ${GQL_FETCH_FD_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_FD_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_FD_CTA_ENTRY}
    }
    ${GQL_FETCH_FD_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HUMANTRAK_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
     sys{
        publishedAt
        firstPublishedAt
      }
      slug
      pageName
      seoMetadata {
        ... on SeoMetaTags {
          description         
          previewImage {
            url
          }
        }
      }
      ${GQL_FETCH_HT_BANNER_ENTRY}
      ${GQL_FETCH_HT_BFR_TRAINING_ENTRY}
      ${GQL_FETCH_HT_HOTSPOTS}
      ${GQL_FETCH_HT_WHY_ELIMINATE_UNCERTAINTY_ENTRY}
      ${GQL_FETCH_HT_CONVENIENCE_ENTRY}
      ${GQL_FETCH_HT_FEATURE_ENTRY}
      ${GQL_FETCH_HT_FLEXIBILITY}
      ${GQL_FETCH_HT_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_HT_CTA_ENTRY}
    }
    ${GQL_FETCH_HT_RELATED_PRODUCTS_ENTRY}
  }
`;

// =============================================
//             TOP NAVIGATION QUERY
// =============================================
export const GQL_FETCH_TOP_NAVIGATION_ENTRY = `
  query {
    topNavigation: topNavigation(id: "7rJqMt1UUhHNRRTkl9DyYN", preview: ${IS_PREVIEW}) {
      logo {
        url
        width
        height
        description
      }
      navItemsCollection(order: order_ASC) {
        items {
          sys {
            id
          }
          slug
          pageName
          subPagesCollection(order: order_ASC) {
            items {
              sys {
                id
              }
              slug
              pageName
              description
            }
          }
        }
      }
    }
  }
`;

export const GQL_GET_POST_METADATA_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vp"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        seoMetadata {
          ... on SeoMetaTags {
            title
            description
            previewImage {
              url
            }
            index
            follow
            canonical
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_POST_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vp"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        slug
        title
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        thumbnail {
          url
        }
        sys {
          id
          publishedAt
          firstPublishedAt
        }
        datePublished
        featuredImage {
          url
          width
          height
          title
          description
          contentType
        }
        languageCode
        translationsCollection(limit: 20) {
          items {
            sys {
                  id
                }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 20) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 20) {
                items {
                  sys {
                    id
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                contentType
                description
              }
            }
            entries {
              __typename
              inline {
                sys {
                  id
                }
                __typename
                ... on Image {
                  title
                  alignment
                  width
                  url
                  media {
                    url
                    height
                    width
                    description
                  }
                }
                ... on BlogComponent {
                  componentType
                  mergedCellTable
                }
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on TextEditor {
                  tinyMce
                }
                ... on Button {
                  text
                  url
                  type
                  target
                }
                ... on Image {
                  media {
                    url
                    width
                    height
                    description
                  }
                  alignment
                  width
                  url
                  bodyCopy {
                    json
                  }
                }
                ... on Video {
                  type
                  url
                  description
                }
                ... on BlogComponent {
                  componentType
                  textBoxColor
                  mergedCellTable
                  bodyCopy {
                    json
                    links {
                      entries {
                        block {
                          sys {
                            id
                          }
                          ... on Image {
                            alignment
                            width
                            url
                            media {
                              url
                              description
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on DuplexComponent {
                  reversed
                  title
                  bodyCopy {
                    json
                  }
                  mediaPrimary {
                    url
                    width
                    height
                    description
                  }
                }
                ... on Grid {
                  sys {
                    id
                  }
                  carousel
                  scrollTo
                  title
                  bodyCopy {
                          json
                        }
                  topicCollection {
                    items {
                      ... on Card {
                        mediaPrimary {
                          width
                          height
                          url
                          description
                        }
                        mediaSecondary {
                          width
                          height
                          url
                          description
                        }
                        title
                        slug
                        bodyCopy {
                          json
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_POST_ENTRY_SLUGS = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vp"]} }}) {
      items {
        slug
      }
    }
  }
`;

export const GQL_FETCH_TOTAL_POST_ENTRY = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vp"]} }}) {
      total
    }
  }
`;

export const GQL_GET_ANNOUNCEMENT_BANNER_ENTRY = `
  query {
     grid(id:"6da7iWp1YXeudskcl3mD7G"){
      title
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
          }
        }
      }
    }
  }
`;

// =============================================
//             TOP ANNOUCEMENT BAR QUERY
// =============================================
export const GQL_FETCH_ANNOUNCEMENT_BAR = `
  query {
    eBooks: grid(id:"6da7iWp1YXeudskcl3mD7G"){
      title
      bodyCopy {
          json
        }
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;
