export const GQL_FETCH_HT_BANNER_ENTRY = `
    humantrakHeroBanner: pageComponentsCollection(where: { sys: { id: "4NrAzVW2xS6Vi20HJkvPAD" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_HT_BFR_TRAINING_ENTRY = `
    imageTextSecondaryBfrTraining: pageComponentsCollection(
      where: {sys: {id: "5w04sGenHOpecNfuAVykfA"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HT_HOTSPOTS = `
  hotspots: pageComponentsCollection(where: { sys: { id: "25SzaaqUw7BaUvcQgueaxu" } }, limit: 1) {
      items {
        ... on DuplexComponent {
          scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        extraAssetsCollection {
            items {
              ... on SectionHeader {
                title
                bodyCopy {
                  json
                }
                buttonCollection(limit: 2) {
                  items {
                    sys {
                      id
                    }
                    text
                    url
                    target
                    type
                  }
                }
              }
            }
          }
      }
    }
  }
`;

// export const GQL_FETCH_HT_WHY_ELIMINATE_UNCERTAINTY_ENTRY = `
//     imageTextSecondaryEliminateUncertainty: pageComponentsCollection(
//       where: {sys: {id: "2c5BJAt6THeaYRs3YTtng1"}}
//       limit: 1
//     ) {
//       items {
//         ... on DuplexComponent {
//           title
//           bodyCopy {
//             json
//           }
//           mediaPrimary {
//             description
//             url
//             width
//             height
//           }
//         }
//       }
//     }
// `;

export const GQL_FETCH_HT_WHY_ELIMINATE_UNCERTAINTY_ENTRY = `
    compensatoryMovements: pageComponentsCollection(
      where: {sys: {id: "2HNEZC3VjjzbJ11XkmHwO2"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HT_CONVENIENCE_ENTRY = `
    patientEngagement: pageComponentsCollection(
      where: {sys: {id: "3BiEp1nO5SJkuOzv1dJcKO"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HT_FEATURE_ENTRY = `
  featureHumanTrak: pageComponentsCollection(where: { sys: { id: "1LrOl6psIQFAOKgxZIW3Ca" } }, limit: 1) {
      items {
        ... on DuplexComponent {
        scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        extraAssetsCollection {
            items {
              ... on SectionHeader {
                title
                bodyCopy {
                  json
                }
                buttonCollection(limit: 2) {
                  items {
                    sys {
                      id
                    }
                    text
                    url
                    target
                    type
                  }
                }
              }
            }
          }
      }
    }
  }
`;

export const GQL_FETCH_HT_FLEXIBILITY = `
    htFlexibility: pageComponentsCollection(
      where: {sys: {id: "PAlmwJenIULQg2yNwdssF"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_HT_RELATED_SYSTEMS_ENTRY = `
    relatedHTSystems: pageComponentsCollection(
      where: {sys: {id: "5fSwWGlJQ874qYVZUsKbuU"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
        }
      }
    }
`;

export const GQL_FETCH_HT_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "5Y0epbL20sooAEKULDxu0L" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_HT_CTA_ENTRY = `
    callToAction: pageComponentsCollection(
      where: {sys: {id: "1fiyxHTGllcb1E0YwfXJPb"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;
