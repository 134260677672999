export const GQL_APPLICATIONS_PAGE_HERO_COMPONENT_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "3tYdjLedKRq91oAjnmNdsk" } }, limit: 1) {
    items {
      ... on Banner {
        title
        bodyCopy {
          json
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_APPLICATIONS_VALDHUB_ENTRY = `
  valdHub: pageComponentsCollection(where: { sys: { id: "7n8UaKxEKzI13zyOrPLsea" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_APPLICATIONS_USAGES_ENTRY = `
  usages: pageComponentsCollection(where: {sys: {id: "13i6QQyZzO9khSdiO7k6d5"}}, limit: 1) {
    items {
      ... on Grid {
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              mediaPrimary {
                width
                height
                url
                description
              }
              title
              bodyCopy {
                json
              }
              buttonCollection(limit: 1) {
                items {
                  ... on Button {
                    url 
                    target
                    text
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_APPLICATIONS_EVERY_PERFORMANCE = `
  everyPerformance: pageComponentsCollection(where: { sys: { id: "2iGE3Osm6SkcHqiqyuKq9S" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_DATA_INFORMED = `
  dataInformed: pageComponentsCollection(where: { sys: { id: "12KLjjBDALEIIYJmOYMfFP" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_MANAGEMENT_INFORMED = `
  management: pageComponentsCollection(where: { sys: { id: "6nwF52gKb2n7KBhXR1hHRk" } }, limit: 1) {
    items {
			... on DuplexComponent {
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_MEASUREMENT = `
  measurement: pageComponentsCollection(where: { sys: { id: "6YBHuIUNz3byeEiHLJMOBn" } }, limit: 1) {
    items {
			... on DuplexComponent {
        title
        preTitle
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_APPLICATIONS_ANALYSIS = `
  analysis: pageComponentsCollection(where: { sys: { id: "Nt2pCEwhlrs7i19CN4j0k" } }, limit: 1) {
    items {
			... on DuplexComponent {
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_NORMATIVE = `
  normativeData: pageComponentsCollection(where: { sys: { id: "4VohXlisbnGKsJPbash5AP" } }, limit: 1) {
    items {
			... on DuplexComponent {
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_ORGANIZATIONS_INTEGRATIONS = `
  integrations: pageComponentsCollection(where: {sys: {id: "7DTyr1txxoN6CYmJNcsSQZ"}}, limit: 1) {
    items {
      ... on SectionHeader {
        preTitle
        title
        bodyCopy {
          json
        }
        logo {
          url
          description
          width
          height
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_TESTIMONIAL = `
  ourSolution: pageComponentsCollection(
    where: {sys: {id: "ne78JbSuOsZYOCDZJNOjv"}}
    limit: 1
  ) {
    items {
      ... on SectionHeader {
        title
      }
    }
  }

  testimonial: pageComponentsCollection(
    where: {sys: {id: "62lJRoxMajcWsuFccpYe79"}}
    limit: 1
  ) {
    items {
      ... on Testimonial {
        title
        name
        company
        bodyCopy {
          json
        }
        profileImage {
          width
          height
          url
          description
        }
        logo {
          url
          width
          description
          height
        }
      }
    }
  }
`;

export const GQL_ORGANIZATIONS_TESTIMONIALS = `
  testimonials: pageComponentsCollection(
    where: {sys: {id: "4bNKzTIMyn7XmJHWwkPumy"}}
    limit: 1
  ) {
    items {
      ... on Grid {
        topicCollection {
          items {
            ... on Testimonial {
              sys {
                id
              }
              title
              name
              company
              bodyCopy {
                json
              }
              logo {
                url
                width
                description
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_APPLICATIONS_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "7FnfnRThc0TTXCAWlQQGyU" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
