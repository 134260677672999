export const GQL_PRODUCTS_PAGE_HERO_COMPONENT_ENTRY = `
  productsHeroBanner: pageComponentsCollection(where: { sys: { id: "3cygaXJ5jn1MdevbHJcOJs" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        title
        bodyCopy {
          json
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_MEASURE_MATTTERS = `
  measureMatters: pageComponentsCollection(where: { sys: { id: "QVToPFo5COfY2NaKJXCy6" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_MEASUREMENT_SOLUTIONS = `
  measureSolutions: pageComponentsCollection(where: { sys: { id: "45guiyGU9pB7tr03ume2Oh" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
        mediaSecondary {
          url
          description
          width
          height
        }
        mediaTertiary {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_CENTRALIZED_PLATFORM_HERO = `
  centralPlatform: pageComponentsCollection(where: { sys: { id: "2gEZDA4MKwoLdbnVD6TmXV" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_NORMS_MEASURE = `
  normsHotSpot: pageComponentsCollection(where: { sys: { id: "56cb3RcJEyYEv5TxWgYfVU" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
        buttonCollection(limit: 2) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_TEN_MILLIONS_DATAPOINTS = `
  dataPoints: pageComponentsCollection( where: {sys: {id: "7fRwnwKZa7Ki660rHqJMWp"}} limit: 1) {
      items {
        ... on SectionHeader {
            scrollTo
            preTitle
            title
            bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              url
              text
              type
              target
            }
          }
        }
      }
    }
`;

export const GQL_PRODUCTS_AIRBANDS_BFR = `
  airbandsBfr: pageComponentsCollection(where: { sys: { id: "fF8JuHQxOY0e8RwEHOF3w" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_EXERCISE_TOOLS = `
  exerciseTools: pageComponentsCollection(where: { sys: { id: "1e7casSGwfrM4a9w1RLfSy" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_ATHLETES_SUCCEED_HERO = `
  athletesSucceed: pageComponentsCollection(where: { sys: { id: "5bFeoVomruhwnz22qdxxpM" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_PRODUCTS_VALD_TEAM_CARDS = `
  valdTeam: pageComponentsCollection(where: {sys: {id: "31naYcJB8pnRhAwxfNop3d"}}, limit: 1) {
    items {
      ... on Grid {
        scrollTo
        bodyCopy {
          json
        }
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              link
              preTitle
              title
              slug
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
              mediaSecondary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_PRODUCTS_SPORTS_CTA = `
  callToAction: pageComponentsCollection(where: { sys: { id: "1fiyxHTGllcb1E0YwfXJPb" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 1) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
