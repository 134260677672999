export const GQL_TELEHAB_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "6QCfZsZ5jVvcOIBoSNu1lw"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_TELEHAB_PRESCRIPTION_ENTRY = `
  prescription: pageComponentsCollection(where: {sys: {id: "Wn2vLL2U9pCIOKUIOSQY8"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_TELEHAB_PROGRAM_BUILD_ENTRY = `
  programBuilder: pageComponentsCollection(where: {sys: {id: "6gIj9YEONV4uBiIb4GqeAH"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_TELEHAB_EXERCISE_LIBRARY_ENTRY = `
  exerciseLibrary: pageComponentsCollection(where: {sys: {id: "5jolmQkDrHIVPKSlwVzzWL"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_TELEHAB_MONITORING_ENTRY = `
  monitoring: pageComponentsCollection(where: {sys: {id: "DtAflKWZOFAnDwt4p2K7L"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_TELEHAB_ENGAGEMENT_ENTRY = `
  engagement: pageComponentsCollection(where: {sys: {id: "1Sr7d5vmBWAQBIhfO1F3w3"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_TELEHAB_RELATED_PRODUCTS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "5hehEOhvxt9fbolCNMOkiA"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_TELEHAB_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "4FWaHQdatprAANnQO2JI7m" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_TELEHAB_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "2OVpW5fCYKWqbMblFPT8wx" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
