export const GQL_FETCH_MK_BANNER_ENTRY = `
    nordbordHeroBanner: pageComponentsCollection(where: { sys: { id: "OVx6rvFAviemcNfDF8ZjK" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_MK_EDUCATION_DELIVERED_ENTRY = `
    imageTextSecondaryEducationDelivered: pageComponentsCollection(
      where: {sys: {id: "6JU82DSDJ7XpFRzSvbEfc7"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MK_CONVENIENCE_ENTRY = `
    patientEngagement: pageComponentsCollection(
      where: {sys: {id: "4KIp4POpMmVmX1IiSSfjjN"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MK_TEST_ECCENTRIC_ENTRY = `
    testEccentric: pageComponentsCollection(
      where: {sys: {id: "3UwvMM47EGQ83JlTWCYRWl"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MK_USABILITY_ENTRY = `
    usability: pageComponentsCollection(
      where: {sys: {id: "CpU7Vz8hihr7tusR7mx9b"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_MK_VALD_SYSTEMS_ENTRY = `
    mskedRelatedSystems: pageComponentsCollection(
      where: {sys: {id: "5eMPFKCXTfvzb9LMtfQzlZ"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
        }
      }
    }
`;

export const GQL_FETCH_MK_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "2xfapX4m3EegtgaX3k6qZG" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_MK_CTA_ENTRY = `
    callToAction: pageComponentsCollection(
      where: {sys: {id: "1fiyxHTGllcb1E0YwfXJPb"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;
