'use client';
import { useRef, createElement, Fragment, useEffect } from 'react';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createRoot } from 'react-dom/client';
import algoliasearch from 'algoliasearch/lite';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import '@algolia/autocomplete-theme-classic';

import { ALGOLIA_API_ADMIN_KEY, ALGOLIA_APP_ID } from '@src/typescriptGlobals/constants';
import SearchResult from '@src/components/Shared/SearchResult/SearchResult';

import { HitItem, Items } from './SearchModal.type';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_ADMIN_KEY);

import './SearchModal.css';

const debouncePromise = <T extends (...args: any[]) => Promise<any>>(fn: T, time: number) => {
  let timerId: ReturnType<typeof setTimeout>;

  return function debounced(...args: Parameters<T>): Promise<ReturnType<T>> {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
};

// Function to send events to Google Analytics
const sendSearchEventToGA = (action: any, params: any) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  }
};

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient,
  indexName: 'vp-site-search_query_suggestions',
  getSearchParams() {
    return {
      hitsPerPage: 3,
    };
  },
  transformSource({ source }) {
    return {
      ...source,
      onSelect({ setQuery, item, setIsOpen }) {
        setQuery(item.query);
        setIsOpen(true);
      },
    };
  },
});

const SearchModal = (props: any) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const panelRootRef = useRef<ReturnType<typeof createRoot> | null>(null);
  const rootRef = useRef<HTMLElement | undefined>();
  const debounced = debouncePromise((items) => Promise.resolve(items), 300);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;
          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }
        panelRootRef.current.render(children);
      },
      plugins: [querySuggestionsPlugin],
      detachedMediaQuery: false,
      insights: true,
      getSources: ({ query }) => {
        return debounced([
          // Query the main pages + product pages
          {
            sourceId: 'general pages',
            getItems() {
              // Google Analytics event: search query
              sendSearchEventToGA('search query', { query });

              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'vp-site-search',
                    query,
                    params: {
                      hitsPerPage: 150,
                      filters: 'pageType: "page"',
                    },
                  },
                ],
              });
            },
            templates: {
              header({ items }: Items) {
                if (items.length === 0) {
                  return null;
                }
                return (
                  <>
                    <span className='aa-SourceHeaderTitle'>Pages</span>
                    <div className='aa-SourceHeaderLine' />
                  </>
                );
              },
              item({ item, components }: HitItem) {
                return <SearchResult item={item} components={components} />;
              },
            },
          },
          // Query the blog posts
          {
            sourceId: 'blog',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                transformResponse({ hits }: any) {
                  const newHits = hits[0].sort(
                    (a: any, b: any) =>
                      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                  );
                  return [newHits];
                },
                queries: [
                  {
                    indexName: 'vp-site-search',
                    query,
                    params: {
                      hitsPerPage: 150,
                      filters: 'pageType: blog',
                    },
                  },
                ],
              });
            },
            templates: {
              header({ items }: Items) {
                if (items.length === 0) {
                  return null;
                }
                return (
                  <>
                    <span className='aa-SourceHeaderTitle'>Blog Articles</span>
                    <div className='aa-SourceHeaderLine' />
                  </>
                );
              },
              item({ item, components }: HitItem) {
                return <SearchResult item={item} components={components} />;
              },
            },
          },
          // Query all pages to get the No results page when no post and no page matched
          {
            sourceId: 'all pages',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'vp-site-search',
                    query,
                    params: {
                      hitsPerPage: 1,
                    },
                  },
                ],
              });
            },
            templates: {
              item() {
                return null;
              },
              noResults() {
                // Google Analytics event: No result search query
                sendSearchEventToGA('No result search query', { query });
                return (
                  <p>
                    No results for <span className='font-[500]'>{`"${query}"`}</span>
                  </p>
                );
              },
            },
          },
        ]);
      },
      onSelect({ item, state }: any) {
        // Google Analytics event: Selected search result
        sendSearchEventToGA('search_select', { query: state.query, pageUrl: item?.pageUrl });
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [debounced, props]);

  return (
    <div className='search-modal-fade fixed left-[0] top-[0] z-[101] flex h-[100%] w-[100%] justify-center'>
      <div
        onClick={props.handleModalOnClick}
        className='fixed left-[0] top-[0] h-[100%] w-[100%] bg-[#00000050]'
      ></div>
      <div className='z-[106] mx-[2rem] mt-[15rem] h-fit w-full shadow-lg md:mx-[0] md:w-[70rem] 3xl:w-[80rem]'>
        <div ref={containerRef}></div>
      </div>
    </div>
  );
};

export default SearchModal;
