export const GQL_FETCH_FD_BANNER_ENTRY = `
    forcedecksHeroBanner: pageComponentsCollection(where: { sys: { id: "3SZ5VPI9EbMkC7ptZLIC3F" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_FD_BFR_TRAINING_ENTRY = `
    imageTextSecondaryBfrTraining: pageComponentsCollection(
      where: {sys: {id: "4l4INqLdYSpenK7NjkS3li"}},
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FD_USABILITY_ENTRY = `
    imageTextSecondaryUsability: pageComponentsCollection(
      where: {sys: {id: "1mb1bEF78dAQvRifeVOxsr"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FD_VERSATILITY_ENTRY = `
  versatility: pageComponentsCollection(where: {sys: {id: "63ITTPo0JS2lenEwisLDH1"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        extraAssetsCollection {
          items {
              ... on DuplexComponent {
                sys {
                  id
                }
                title
                mediaPrimary {
                description
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_FD_CONVENIENCE_ENTRY = `
    centreOfPressure: pageComponentsCollection(
      where: {sys: {id: "4USRXejocWarwonUjBnwQl"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FD_FULLY_FEATURED_ENTRY = `
    fdVision: pageComponentsCollection(
      where: {sys: {id: "4pOmDj2v4UY2lRd35idMmD"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }

        }
      }
    }
`;

export const GQL_FETCH_INTRO_NORMS_ENTRY = `
    introNorms: pageComponentsCollection(
      where: {sys: {id: "15teV1NO6iVUCYscHDcRTo"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }

        }
      }
    }
`;

export const GQL_FETCH_FD_VALD_SYSTEMS_ENTRY = `
  fitForPurpose: pageComponentsCollection(where: { sys: { id: "2yEHvr7OCgVZD0VTMMBPht" } }, limit: 1) {
      items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
            buttonCollection(limit: 2) {
                items {
                  sys {
                    id
                  }
                  text
                  url
                  target
                  type
                }
              }
        extraAssetsCollection(limit: 3) {
          items {
            ... on DuplexComponent {
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                width
                height
                description
              }
              mediaSecondary {
                url
                width
                height
                description
              }
              buttonCollection(limit: 2) {
                items {
                  sys {
                    id
                  }
                  text
                  url
                  target
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_FD_BLOG_CAROUSEL_ENTRY = `
blogCarousel: pageComponentsCollection(where: { sys: { id: "4yY1RkSkAbyasQ2FCyYML4" } }, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_FD_BLOG_CAROUSEL_POSTS_ENTRY = `
carouselPosts: pageComponentsCollection(where: { sys: { id: "4OgajvkLbjC3NF8EKBQRh9" } }, limit: 1) {
    items {
      ... on Grid {
        sys {
          id
        }
        scrollTo
        topicCollection {
          items {
            ... on Post {
              sys {
                id
              }
              title
              slug
              thumbnail {
                url
                width
                height
                description
              }
            }
          }
        }
        
      }
    }
}
`;

export const GQL_FETCH_FD_FLEXIBILITY_ENTRY = `
  flexibility: pageComponentsCollection(
      where: {sys: {id: "5Rkebgu73CMLeBuRwfra7n"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FD_VALIDATED_ENTRY = `
  validated: pageComponentsCollection(
      where: {sys: {id: "5sxgeNAlQtJrr5Megi00lj"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FD_ACCESSORIES_ENTRY = `
  accessories: pageComponentsCollection(
      where: {sys: {id: "4GBPjUzfkoLBg9zyTwfaag"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FD_RELATED_SYSTEMS_ENTRY = `
    relatedFDSystems: pageComponentsCollection(
      where: {sys: {id: "5TGk5Lr8gD3Da2pY1dH3DX"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
        }
      }
    }
`;

export const GQL_FETCH_FD_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "6dArSAjXctRP9waUrvUPa6" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_FD_CTA_ENTRY = `
    callToAction: pageComponentsCollection(
      where: {sys: {id: "6TxrLQt6T3iGugomlXsTS7"}},
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          preTitle
          scrollTo
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;
