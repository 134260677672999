export const GQL_SCOREIT_HERO_ENTRY = `
  heroBanner: pageComponentsCollection(where: {sys: {id: "4e0JO6fBRdQf7gq3JoDCsi"}}, limit: 1) {
   items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_SCOREIT_DIGITAL_PROMS_ENTRY = `
  digitalProms: pageComponentsCollection(where: {sys: {id: "5KDRuw3ENa1iaEMnmrqU0S"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SCOREIT_CONVENIENT_ENTRY = `
  convenient: pageComponentsCollection(where: {sys: {id: "1V3wjGNnAnmtxDo9EMqlXw"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SCOREIT_VALIDATED_ENTRY = `
  validated: pageComponentsCollection(where: {sys: {id: "4xbwQacVmaWRrAioJrT2Ra"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_SCOREIT_DELIVERED_ENTRY = `
  delivered: pageComponentsCollection(where: {sys: {id: "1nQpE99BqzIAma3JOrneNR"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SCOREIT_PROMS_LIBRARY_ENTRY = `
  promsLibrary: pageComponentsCollection(where: {sys: {id: "4SpfX4OqwpCt1DQb2DWAMm"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_SCOREIT_RELATED_PRODUCTS_ENTRY = `
 relatedProducts: pageComponentsCollection(where: {sys: {id: "M050TqCP1txC0B7GClSJJ"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
      }
    }
  }
`;

export const GQL_SCOREIT_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "0kAIm5Oyc7PMFradjIKTM" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_SCOREIT_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "536dTrbAFeDkjXmg6xCDtu" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        sys {
          id
        }
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
