'use client';
import React from 'react';
import Script from 'next/script';

import { ThirdPartyScriptsPropTypes } from './ThirdPartyScripts.types';

declare global {
  interface Window {
    gsap?: any;
    ScrollTrigger?: any;
    ScrollToPlugin?: any;
  }
}

const ThirdPartyScripts: React.FC<ThirdPartyScriptsPropTypes> = () => {
  // Function to register ScrollTrigger plugin
  const handleLoadScrollTrigger = () => {
    if (window.gsap && window.ScrollTrigger) {
      window.gsap.registerPlugin(window.ScrollTrigger);
    }
  };

  // Function to register ScrollToPlugin plugin
  const handleLoadScrollToPlugin = () => {
    if (window.gsap && window.ScrollToPlugin) {
      window.gsap.registerPlugin(window.ScrollToPlugin);
    }
  };

  return (
    <>
      {/* Load Google Analytics and Google Tag Manager scripts */}
      <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-56N8R77'
          height='0'
          width='0'
          className='invisible hidden'
        />
      </noscript>

      <Script id='google-analytics' strategy='lazyOnload'>
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'GTM-56N8R77');
            `}
      </Script>
      <Script id='google-tag-manager' strategy='lazyOnload'>
        {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-56N8R77');
            `}
      </Script>

      {/* GSAP Scripts */}
      <Script src='https://cdn.jsdelivr.net/npm/gsap@3/dist/gsap.min.js' strategy='lazyOnload' />
      <Script
        src='https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollTrigger.min.js'
        strategy='lazyOnload'
        onLoad={handleLoadScrollTrigger}
      />
      <Script
        src='https://cdn.jsdelivr.net/npm/gsap@3.12.5/dist/ScrollToPlugin.min.js'
        strategy='lazyOnload'
        onLoad={handleLoadScrollToPlugin}
      />

      {/* Script for marker.io */}
      {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'live' && (
        <Script
          id='marker-io'
          dangerouslySetInnerHTML={{
            __html: `
    window.markerConfig = {
      project: '6690738688a95281f5927b09',
      source: 'snippet'
    };

    !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
  `,
          }}
        />
      )}
    </>
  );
};

export default ThirdPartyScripts;
