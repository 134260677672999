export const GQL_FETCH_BANNER_ENTRY = `
    airbandsHeroBanner: pageComponentsCollection(where: { sys: { id: "3PsmxQOvr0yiyRMXmoTQaP" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_BFR_TRAINING_ENTRY = `
    imageTextSecondaryBfrTraining: pageComponentsCollection(
      where: {sys: {id: "38tgSQqfTzvPKdQpVxKMXn"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_WHY_BFR_ENTRY = `
    imageTextSecondaryWhyBfrTraining: pageComponentsCollection(
      where: {sys: {id: "7gk7POH2luQ5btPAKGpdDT"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_WHY_ELIMINATE_UNCERTAINTY_ENTRY = `
    imageTextSecondaryEliminateUncertainty: pageComponentsCollection(
      where: {sys: {id: "2c5BJAt6THeaYRs3YTtng1"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_CONVENIENCE_ENTRY = `
    videoTextConvenience: pageComponentsCollection(
      where: {sys: {id: "rFk2h6grb18Dj75bZlanA"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
          }
        }
      }
    }
`;

export const GQL_FETCH_FULLY_FEATURED_ENTRY = `
    fullyFeatured: pageComponentsCollection(
      where: {sys: {id: "223f4Deub4MHEptBfDN9f2"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            url
            description
          }
          mediaSecondary {
            url
            description
          }
          mediaTertiary {
            url
            description
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_VALD_SYSTEMS_ENTRY = `
    relatedSystems: pageComponentsCollection(
      where: {sys: {id: "5Gqq7zZ1GCX6nQVP2O2wrA"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
        }
      }
    }
`;

export const GQL_FETCH_AIRBANDS_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "5HQQp3cWrNw9Q3tknR28vn" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_AIRBANDS_CTA_ENTRY = `
    callToAction: pageComponentsCollection(
      where: {sys: {id: "1kVnOra8d3KPFDhXt1LBn6"}}
      limit: 1
    ) {
      items {
        ... on SectionHeader {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;
